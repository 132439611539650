<template>
	<div>
		<a-spin :spinning="loading">
			<div class="pd30">
				<div>
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						<a-radio-button :value="1">
							待付款
						</a-radio-button>
						<a-radio-button :value="2">
							待发货
						</a-radio-button>
						<a-radio-button :value="3">
							已发货
						</a-radio-button>
						<a-radio-button :value="4">
							待到店自提
						</a-radio-button>
						<a-radio-button :value="8">
							已完成
						</a-radio-button>
						<a-radio-button :value="-1">
							已取消
						</a-radio-button>
					</a-radio-group>
				</div>
			
				<div class="mt30" style="height: 700px;overflow: auto;" v-if="datas.length>0">
					<div class="member-detail-order-item" v-for="(order,index) in datas">
						<div class="member-detail-order-item-header">
							<div class="flex">
								<div style="width: 83%;" class="flex alcenter ">
									<div class="member-detail-order-item-tag ml20" v-if="order.logistics_type==1" style="background: #4772FF;">快递单</div>
									<div class="member-detail-order-item-tag ml20" v-if="order.logistics_type==2" style="background: #00BA26;">自提单</div>
									<div class="ml8 ">
										<div class="flex alcenter">
											<div class="flex alcenter">
												<div class="ft14 ftw400 cl-info text-over4">订单编号：</div>
												<div class="ft14 ftw600 cl-main text-over4">{{order.order_id}}</div>
											</div>
											
											<div class="flex alcenter ml40">
												<div class="ft14 ftw400 cl-info text-over4">下单时间：</div>
												<div class="ft14 ftw600 cl-main text-over4">{{order.add_time_format}}</div>
											</div>
											
											<div class="flex alcenter ml40">
												<div class="ft14 ftw400 cl-info text-over4">订单类型：</div>
												<div class="ft14 ftw600 cl-theme text-over4">
													{{order.order_type_means}}</div>
											</div>
										</div>
									</div>
								</div>
								<div style="width: 17%;" class="flex center">
									操作
								</div>
							</div>
						</div>
						<div>
							<div class="flex">
								<div style="width: 40%;" >
									<div>
										<div class="member-detail-order-item-goods" v-for="(item,item_index) in order.skus">
											<div class="flex space alcenter">
												<div class="flex alcenter">
													<img v-if="item.goods_cover_img != null" :src="item.goods_cover_img" style="width: 60px;height: 60px;"/>
													<div class="ml10">
														<div class="ft14 ftw500 cl-info" style="width: 80%;">
															{{item.goods_name}}
														</div>
														<div class="ft12 ftw400 cl-notice">{{item.goods_sku_name}}</div>
													</div>
												</div>
												<div class="flex alcenter ft14 ftw500 cl-info">
													<div>¥{{item.goods_price}}</div>
													<div class="ml8">x{{item.goods_num}}</div>
												</div>
											</div>
										</div>
										
									</div>
								</div>
								<div style="width: 60%;" class="flex">
									<div class="flex alcenter center" style="width: 33%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
										<div class="ft12 ftw400 cl-info">
											<div class="flex alcenter center">
												<div class="text-center">合计：¥{{order.total_price}}</div>
												<div class="text-center ml15" v-if="order.logistics_type==1">
													运费：¥{{order.freight}}</div>
											</div>
											<div class="mt5 text-center" v-if="order.coupon_money > 0">
												优惠券抵扣：¥{{order.coupon_money}}</div>
											<div class="mt5 text-center" v-if="order.integral_balance > 0">
												积分抵扣：¥{{order.integral_balance}}</div>
											<div class="mt5 text-center"
												v-if="order.pay_type=='offline' && order.youhui_balance > 0">
												优惠金额：¥{{order.youhui_balance}}</div>
											<div class="ft14 ftw500 cl-black mt5 text-center">
												实付金额：¥{{order.need_pay}}</div>
										</div>
									</div>
									<div class="flex alcenter center" style="width: 33%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
										<div class="ft14 ftw500 cl-info">
											{{order.status_means}}
										</div>
									</div>
									<div class="flex alcenter center" style="width: 33%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
										<div class="ft14 ftw500 cl-info">
											<a-dropdown placement="bottomRight">
												<span class="more-act">
													<i class="iconfont iconmore_gray"></i>
												</span>
												<a-menu slot="overlay">
													  <a-menu-item v-if="order.logistics_type==1 && order.status==2">
													  	<a class="menu-act" href="javascript:;" @click="deliverGoodsAct(index)">
													  		<i class="iconfont ft14 icondelivery"></i>
													  		<span class="ml10">确认发货</span>
													  	</a>
													  </a-menu-item>
													  <a-menu-item v-if="order.logistics_type==1 && order.status==3">
													  	<a class="menu-act" href="javascript:;" @click="overGoodsAct(index)">
													  		<i class="iconfont ft14 icondelivery"></i>
													  		<span class="ml10">确认完成</span>
													  	</a>
													  </a-menu-item>
													  <a-menu-item v-if="order.logistics_type==2 && order.status==4">
													  	<a class="menu-act" href="javascript:;" @click="writeOffAct(index)">
													  		<i class="iconfont ft14 iconhexiao1"></i>
													  		<span class="ml10">确认核销</span>
													  	</a>
													  </a-menu-item>
												</a-menu>
											  </a-dropdown>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>							
				</div>
			
				<div class="mt30 flex center" v-else>
					<a-empty />
				</div>
				
				<div class="flex center mt20">
					<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current"
						:pageSize="pagination.pageSize" show-less-items />
				</div>
			</div>
		</a-spin>
	</div>
				
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default {
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					status: 1,
				},
				datas:[
					
				]
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getGoodsOrderList',{
					member_id:this.member_id,
					status: this.search.status,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			overGoodsAct(index){
				this.$confirm({
					title:'确认完成这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('overGoodsOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('完成成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			deliverGoodsAct(index){
				this.$confirm({
					title:'确认发货这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('deliverGoodsOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('发货成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			writeOffAct(index){
				this.$confirm({
					title:'确定核销这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((ressolve,reject)=>{
							this.$http.api('writeOffOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('核销成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			changeStatus() {
				this.pagination.current = 1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	
	.member-detail-order-item{
		width: 100%;
		margin-bottom: 20px;
	}
	
	.member-detail-order-item-header{
		width: 100%;
		height: 50px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		line-height: 50px;
	}
	
	.member-detail-order-item-tag{
		padding: 1px 8px;
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}
	
	.member-detail-order-item-goods{
		padding: 30px 20px;
		border: 1px solid #EBEDF5;
		border-top: none;
	}
	.member-detail-order-item-goods.tag{
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
</style>
