<template>
	<div>
		<div class="pd30">
			<div class="wxb-table-gray">
				<a-table rowKey="member_integral_logs_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="text-center" slot="integral" slot-scope="record">
						{{record.type==1 ? '+' : '-'}} {{record.integral}}
					</div>
					
					<div class="text-center" slot="remarks" slot-scope="remarks,record">
						{{remarks ? remarks : '-'}}
					</div>
					
					<div class="text-center" slot="operator" slot-scope="record">
						<div v-if="record.operate_name && record.operate_mobile">
							<div class="ft14 cl-info">{{record.operate_name}}</div>
							<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
						</div>
						<div v-else>-</div>
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '类型',dataIndex: 'info',align: 'center',ellipsis: true},
					{title: '积分',key: 'integral',align: 'center',scopedSlots: {customRender: 'integral'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '备注信息',dataIndex: 'remarks',align: 'center',scopedSlots: {customRender: 'remarks'}},
					{title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
					
				],
				datas:[]
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getMemberIntegralLogsList',{
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
