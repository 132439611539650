<template>
	<div>
		<a-modal title="积分扣除" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定扣除
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<div class="flex center">
						<div class="detail-modal-integral-deduction">
							<div class="detail-modal-integral-deduction-content">
								<div class="pt15 ft14 ftw600 cl-main">扣除积分</div>
								<div style="position: relative;" class="mt15">
									<a-input-number  v-model="form.integral" :precision="0" :min="0" >
										
									</a-input-number>
									<div class="input-number-addon flex alcenter">
										<img src="../../../../../assets/image/woyaoyou_icon_integral@2x.png"/>
									</div>
								</div>
							</div>
							
							<div class="detail-modal-integral-deduction-footer flex space alcenter">
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-main">当前积分</div>
									<div class="ft16 ftw600 cl-black ml8">{{member.integral}}</div>
								</div>
								
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-main">扣除后剩余</div>
									<div class="ft16 ftw600 cl-orange ml8">{{remain_integral}}</div>
								</div>
							</div>
						</div>
					</div>
								
					<div class="flex center mt24">
						<div class="detail-modal-integral-deduction-info">
							<a-input style="width: 420px;" placeholder="如非会员本人消费，请备注好关系姓名" v-model="form.remarks" :maxLength="20">
								<div slot="prefix" class="input-addon-before">
									备注
								</div>
							</a-input>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				member:{
					integral:0,
				},
				form: {
					integral:0,
					remarks:'',
				}
			}
		},
		computed:{
			remain_integral(){
				return (this.member.integral-this.form.integral)
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return true;
				this.loading=true;
				this.$http.api('showMember',{
					member_id:this.member_id
				}).then(res=>{
					this.member=res.detail;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
					this.$emit("cancel");
				})
			},
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('integralDeduction',{
					member_id:this.member_id,
					integral:this.form.integral,
					remarks:this.form.remarks,
				}).then(res=>{
					this.$message.success('扣除成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	.detail-modal-integral-deduction{
		width: 360px;
		height: 178px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 20px;
	}
	
	.detail-modal-integral-deduction-content{
		height: 125px;
		border-bottom: 1px solid #DCDFE6;
	}
	
	.detail-modal-integral-deduction-content .ant-input-number {
		
		font-size: 40px;
		line-height: 50px;
		font-weight: bold;
		border: none;
		color: #A6AEC2;
		width: 300px;
		height: 50px;
		border-radius: 4px;
		
	}
	
	
	.detail-modal-integral-deduction-content .ant-input-number-input {
		width: 300px;
		height: 50px;
		padding: 0 59px;
		border-radius: 4px;
	}
	
	.detail-modal-integral-deduction-content .ant-input-number-handler-wrap {
		display: none;
	}
	
	.detail-modal-integral-deduction-content .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-integral-deduction-content .input-number-addon{
		height: 50px;
		padding-left: 20px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 50px;
		
		font-size: 40px;
		font-weight: bold;
		color: #232529;
	}
	
	.detail-modal-integral-deduction-content .input-number-addon img{
		width: 24px;
	}
	
	.detail-modal-integral-deduction-footer{
		height: 51px;
	}
	
	.detail-modal-integral-deduction-info .ant-input-affix-wrapper .ant-input-prefix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 35px;
	}
	
	.detail-modal-integral-deduction-info .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 59px;
	}
</style>
