<template>
	<div>
		<a-spin :spinning="loading">
			<div style="min-height: calc(100vh - 60px);" class="bg-w flex ">
				<div class="member-detail-left">
					<div class="member-detail-left-header">
						<div class="flex alcenter">
							<img v-if="detail.face != null" :src="detail.face" class="member-detail-face"/>
							<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-detail-face"/>
							<div class="ml15">
								<div class="flex alcenter ">
									<div class="ft16 ftw600 cl-main text-over4">{{detail.nick_name}}</div>
									<div class="member-detail-vip-iden ml4 text-over4">{{detail.level_name}}</div>
								</div>
								
								<div class="mt15 flex alcenter">
									<i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
									<div class="cl-info ft12 ftw400 text-over4">{{detail.mobile}}</div>
								</div>
							</div>
						</div>
						
						<!-- <div class="mt16">
							<div class="member-detail-left-header-action">
								<div class="flex space alcenter" style="width: 100%;height: 100%;">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-main">顾问：</div>
										<div class="ft14  cl-main" style="font-weight: 800;">{{detail.adviser}}</div>
									</div>
									<div class="flex alcenter ft14 cl-theme clickAct" @click="assignAdviserAct()" style="font-weight: 800;">
										<i class="iconfont iconassignment mr2"/>指派顾问
									</div>
								</div>
							</div>
						</div> -->
					</div>
					
					<div class="member-detail-left-content">
						
						<div class="member-detail-left-content-item">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">持有次卡</div>
								<div class="ft20 ftw600 cl-theme">{{detail.once_card_num}}张</div>
							</div>
						</div>
						
						<div class="member-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">账户余额</div>
								<div class="ft20 ftw600 cl-theme">￥{{detail.balance}}</div>
							</div>
						</div>
						
			
						
						<div class="member-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">优惠券</div>
								<div class="ft20 ftw600 cl-theme">{{detail.coupon_count}}张</div>
							</div>
						</div>
						
						<div class="member-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">可用积分</div>
								<div class="ft20 ftw600 cl-theme">{{detail.integral}}</div>
							</div>
						</div>
					</div>
					
					<div class="member-detail-left-footer">
						<div class="flex alcenter">
							<div class="member-detail-left-footer-item" @click="balanceRechargeAct()">
								<i class="iconfont iconoperation_cate01 flex center ft20" style="color: #FC9A3F;"/>
								<div class="ft14 ftw600 cl-main text-center">余额充值</div>
							</div>
							
							<div class="member-detail-left-footer-item ml16" @click="balanceDeductionAct()">
								<i class="iconfont iconoperation_cate02 flex center ft20" style="color: #F76260;"/>
								<div class="ft14 ftw600 cl-main text-center">余额核销</div>
							</div>
							
							<div class="member-detail-left-footer-item ml16" @click="applyCardAct()">
								<i class="iconfont iconoperation_cate031 flex center ft20" style="color: #00C657;"/>
								<div class="ft14 ftw600 cl-main text-center">办理次卡</div>
							</div>
							
						</div>
						
						<div class="flex alcenter mt16">
							<div class="member-detail-left-footer-item " @click="integralRechargeAct()">
								<i class="iconfont iconoperation_cate04 flex center ft20" style="color: #E8B000;"/>
								<div class="ft14 ftw600 cl-main text-center">积分充值</div>
							</div>
						
							<div class="member-detail-left-footer-item ml16" @click="integralDeductionAct()">
								<i class="iconfont iconoperation_cate05 flex center ft20" style="color: #597EF7;"/>
								<div class="ft14 ftw600 cl-main text-center">积分扣除</div>
							</div>
							
							<div class="member-detail-left-footer-item ml16" @click="sendCouponAct()">
								<i class="iconfont iconhome_nav04 flex center ft20" style="color: #FE8340;"/>
								<div class="ft14 ftw600 cl-main text-center">送券</div>
							</div>
						</div>
					</div>
				</div>
			
				<div class="member-detail-right">
					<a-layout>
						<a-layout-header class="layout-classes-header">
							<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
								<a-menu-item key="mall_order">商城订单</a-menu-item>
								<a-menu-item key="cash_order">收银订单</a-menu-item>
								<a-menu-item key="oncecard">次卡</a-menu-item>
								<a-menu-item key="flash_sale">抢购</a-menu-item>
								<a-menu-item key="coupon">优惠券</a-menu-item>
								<a-menu-item key="balance">余额明细</a-menu-item>
								<a-menu-item key="integral">积分记录</a-menu-item>
								<a-menu-item key="buy">买单记录</a-menu-item>
								<!-- <a-menu-item key="follow">跟进记录</a-menu-item> -->
								
							</a-menu>
						</a-layout-header>
						<a-layout-content class="layout-classes-content">
							<div v-if="selectCurrent == 'mall_order'">
								<order-detail :member_id="member_id"></order-detail>
							</div>
							<div v-if="selectCurrent == 'cash_order'">
								<cash-detail :member_id="member_id"></cash-detail>
							</div>
							<div v-if="selectCurrent == 'oncecard'">
								<oncecard-detail :member_id="member_id"></oncecard-detail>
							</div>
							<div v-if="selectCurrent == 'flash_sale'">
								<flashsale-detail :member_id="member_id"></flashsale-detail>
							</div>
							<div v-if="selectCurrent == 'coupon'">
								<coupon-detail :member_id="member_id"></coupon-detail>
							</div>
							<div v-if="selectCurrent == 'balance'">
								<balance-detail :member_id="member_id"></balance-detail>
							</div>
							<div v-if="selectCurrent == 'integral'">
								<integral-detail :member_id="member_id"></integral-detail>
							</div>
							<div v-if="selectCurrent == 'buy'">
								<buy-detail :member_id="member_id"></buy-detail>
							</div>
							<!-- <div v-if="selectCurrent == 'follow'">
								<follow-detail :member_id="member_id"></follow-detail>
							</div> -->
						</a-layout-content>
					</a-layout>
				</div>
			
				
				<div v-if="balanceRechargeLoading">
					<balance-recharge :member_id="member_id" :visible="balanceRechargeLoading" @cancel="cancelBalanceRecharge" @ok="okBalanceRecharge"></balance-recharge>
				</div>
				<div v-if="balanceDeductionLoading">
					<balance-deduction :member_id="member_id" :visible="balanceDeductionLoading" @cancel="cancelBalanceDeduction" @ok="okBalanceDeduction"></balance-deduction>
				</div>
				<div v-if="applyCardLoading">
					<apply-card :member_id="member_id" :visible="applyCardLoading" @cancel="cancelApplyCard" @ok="okApplyCard"></apply-card>
				</div>
				<div v-if="integralRechargeLoading">
					<integral-recharge :member_id="member_id" :visible="integralRechargeLoading" @cancel="cancelIntegralRecharge" @ok="okIntegralRecharge"></integral-recharge>
				</div>
				<div v-if="integralDeductionLoading">
					<integral-deduction :member_id="member_id" :visible="integralDeductionLoading" @cancel="cancelIntegralDeduction" @ok="okIntegralDeduction"></integral-deduction>
				</div>
				<div v-if="sendCouponLoading">
					<send-coupon :member_id="member_id" :visible="sendCouponLoading" @cancel="cancelSendCoupon" @ok="okSendCoupon"></send-coupon>
				</div>
				<div v-if="assignAdviserLoading">
					<assign-adviser :member_id="member_id" :staff_id="detail.staff_id" :visible="assignAdviserLoading" @cancel="cancelAssignAdviser" @ok="okAssignAdviser"></assign-adviser>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import orderDetail from './components/detail/order.vue';
	import cashDetail from './components/detail/cash.vue';
	import oncecardDetail from './components/detail/oncecard.vue';
	import flashsaleDetail from './components/detail/flashsale.vue';
	import couponDetail from './components/detail/coupon.vue';
	import balanceDetail from './components/detail/balance.vue';
	import integralDetail from './components/detail/integral.vue';
	import buyDetail from './components/detail/buy.vue';
	
	import balanceRecharge from './components/detail/modal/balanceRecharge.vue';
	import balanceDeduction from './components/detail/modal/balanceDeduction.vue';
	import applyCard from './components/detail/modal/applyCard.vue';
	import integralRecharge from './components/detail/modal/integralRecharge.vue';
	import integralDeduction from './components/detail/modal/integralDeduction.vue';
	import sendCoupon from './components/detail/modal/sendCoupon.vue';
	import assignAdviser from './components/detail/modal/assignAdviser.vue';
	export default{
		components:{
			orderDetail,
			cashDetail,
			oncecardDetail,
			flashsaleDetail,
			couponDetail,
			balanceDetail,
			integralDetail,
			buyDetail,
			
			balanceRecharge,
			balanceDeduction,
			applyCard,
			integralRecharge,
			integralDeduction,
			sendCoupon,
			assignAdviser,
		},
		data(){
			return{
				loading:false,
				member_id:0,
				selectCurrent: 'mall_order',
				balanceRechargeLoading:false,
				balanceDeductionLoading:false,
				applyCardLoading:false,
				integralRechargeLoading:false,
				integralDeductionLoading:false,
				sendCouponLoading:false,
				assignAdviserLoading:false,
				detail:{
					nick_name:'',
					level_name:'',
					mobile:'',
					face:'',
					once_card_num:0,
					adviser:'',
					balance:0,
					integral:0,
					coupon_count:0,
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.member_id=0;
			} else {
				this.member_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getMemberDetail',{
					member_id:this.member_id
				}).then(res=>{
					this.detail=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
			
			// 余额充值
			balanceRechargeAct(){
				this.balanceRechargeLoading=true;
			},
			cancelBalanceRecharge(){
				this.balanceRechargeLoading=false;
			},
			okBalanceRecharge(){
				this.balanceRechargeLoading=false;
				this.loaddata();
			},
			
			// 余额扣除
			balanceDeductionAct(){
				this.balanceDeductionLoading=true;
			},
			cancelBalanceDeduction(){
				this.balanceDeductionLoading=false;
			},
			okBalanceDeduction(){
				this.balanceDeductionLoading=false;
				this.loaddata();
			},
			
			//办理次卡
			applyCardAct(){
				this.applyCardLoading=true;
			},
			cancelApplyCard(){
				this.applyCardLoading=false;
			},
			okApplyCard(){
				this.applyCardLoading=false;
				this.loaddata();
			},
			
	
			
			//积分充值
			integralRechargeAct(){
				this.integralRechargeLoading=true;
			},
			cancelIntegralRecharge(){
				this.integralRechargeLoading=false;
			},
			okIntegralRecharge(){
				this.integralRechargeLoading=false;
				this.loaddata();
			},
			
			//积分扣除
			integralDeductionAct(){
				this.integralDeductionLoading=true;
			},
			cancelIntegralDeduction(){
				this.integralDeductionLoading=false;
			},
			okIntegralDeduction(){
				this.integralDeductionLoading=false;
				this.loaddata();
			},
			
			//送券
			sendCouponAct(){
				this.sendCouponLoading=true;
			},
			cancelSendCoupon(){
				this.sendCouponLoading=false;
			},
			okSendCoupon(){
				this.sendCouponLoading=false;
				this.loaddata();
			},
			
			//指派顾问
			assignAdviserAct(){
				this.assignAdviserLoading=true;
			},
			cancelAssignAdviser(){
				this.assignAdviserLoading=false;
			},
			okAssignAdviser(){
				this.assignAdviserLoading=false;
				this.loaddata();
			},
			
		}
	}
</script>

<style>
	.member-detail-left{
		width: 319px;
		min-height: 800px;
		border-right: 1px solid #EBEDF5;
	}
	.member-detail-left-header{
		/* height: 128px; */
		padding: 24px;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.member-detail-left-header-action{
		width: 272px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 24px;
	}
	.member-detail-face{
		width: 80px;
		height: 80px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}
	.member-detail-vip-iden{
		padding: 2px 6px 2px 6px;
		border-radius: 2px;
		border: 1px solid #EBEDF5;
		
		font-size: 12px;
		font-weight: 600;
		color: #4772FF;
	}
	.member-detail-left-content{
		padding: 24px;
	}
	
	.member-detail-left-content-item{
		width: 100%;
		height: 72px;
		background: #F7F8FC;
		border-radius: 8px;
		padding: 20px;
	}
	
	.member-detail-left-footer{
		padding: 24px;
	}
	
	.member-detail-left-footer-item{
		width: 80px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		cursor: pointer;
		padding: 15px 10px 15px 10px;
	}
	
	.member-detail-right{
		width: 100%;
	}
	
	
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
