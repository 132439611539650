<template>
	<div>
		<div class="">
			<a-radio-group v-model="search.type" button-style="solid" @change="changeType">
				<a-radio-button :value="1">
				  消费记录
				</a-radio-button>
				<a-radio-button :value="2">
				  办理记录
				</a-radio-button>
			</a-radio-group>
		</div>
		<div class="mt20">
			<div class="wxb-table-gray" v-if="search.type==1">
				<a-table rowKey="write_off_id" :columns="columns1" :pagination="pagination" @change="handleTableChange" :data-source="datas1"
				 :loading="loading">
					<div class="flex alcenter center" slot="once_card" slot-scope="once_card,record">
						{{once_card.name}}
					</div>
					
					<div class="flex alcenter center" slot="num" slot-scope="num,record">
						<div >-{{num}}次</div>
					</div>
					
					<div  slot="operator" slot-scope="record">
						<div class="flex alcenter center" v-if="record.operate_type==0">-</div>
						<div class="flex alcenter center" v-if="record.operate_type==1">
							[员工]{{record.operate_name}} {{record.operate_mobile}}
						</div>
						<div class="flex alcenter center" v-if="record.operate_type==2">
							[运营者]{{record.operate_name}} {{record.operate_mobile}}
						</div>
					</div>
				</a-table>
			</div>
			
			<div class="wxb-table-gray" v-if="search.type==2">
				<a-table rowKey="apply_logs_id" :columns="columns2" :pagination="pagination" @change="handleTableChange" :data-source="datas2"
				 :loading="loading">
				 
					 <div class="flex alcenter center" slot="price" slot-scope="price,record">
						¥{{price}}
					 </div>
				 
					<div class="flex alcenter center" slot="market_price" slot-scope="market_price,record">
						¥{{market_price}}
					</div>
				 
					<div  slot="operator" slot-scope="record">
						<div class="flex alcenter center" v-if="record.operate_type==0">-</div>
						<div class="flex alcenter center" v-if="record.operate_type==1">
							[员工]{{record.operate_name}} {{record.operate_mobile}}
						</div>
						<div class="flex alcenter center" v-if="record.operate_type==2">
							[运营者]{{record.operate_name}} {{record.operate_mobile}}
						</div>
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../../../common/mixin/list.js';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins: [listMixin],
		data(){
			return{
				loading: false,
				search:{
					type:1,
				},
				pagination: {
					current: 1,
					pageSize: 5, //每页中显示10条数据
					total: 0,
				},
				columns1: [
					{title: '次卡名称',dataIndex: 'once_card',align: 'center',scopedSlots: {customRender: 'once_card'}},
					{title: '项目名称',dataIndex: 'abbreviation',align: 'center',ellipsis: true},
					{title: '核销次数',dataIndex: 'num',align: 'center',scopedSlots: {customRender: 'num'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
				],
				columns2: [
					{title: '次卡名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '实际办理价格',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '门市原价',dataIndex: 'market_price',align: 'center',scopedSlots: {customRender: 'market_price'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
				],
				datas1: [],
				datas2: [],
			}
		},
		methods:{
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('getMemberOnceCardLogs', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					member_id: this.member_id,
					type:this.search.type,
				}).then(res => {
					this.pagination.total = res.total;
					if(this.search.type==1){
						this.datas1 = res.list;
					}else{
						this.datas2 = res.list;
					}
					
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			changeType(){
				this.pagination={
					current: 1,
					pageSize: 5, //每页中显示10条数据
					total: 0,
				},
				this.getLists();
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.record-list-title{
		padding-left: 10px;
		border-left: 4px solid #4772FF;
		height: 20px;
		
		font-size: 16px;
		font-weight: 600;
		color: #232529;
		line-height: 20px;
	}
</style>
