<template>
	<div>
		<a-spin :spinning="loading">
			<div class="flex wrap" v-if="datas.length>0">
				<div class="detail-coupon-item" v-for="(item,index) in datas">
					<div class="flex space alcenter">
						<div>
							<div class="text-over4 ft14 ftw600 cl-black">{{item.name}}</div>
							<div class="mt15 ft12 ftw400 cl-notice text-over4">满{{item.money_limit}}元可用，不可与其他优惠活动同时使用</div>
							<div class="mt10 ft12 ftw400 cl-info text-over4">有效期：{{item.valid_bg_time}}-{{item.valid_end_time}}</div>
						</div>
						
						<div>
							<div class=" flex end">
								<div class="flex alcenter">
									<div class="ft12 ftwblod cl-price">¥</div>
									<div class="ft24 ftwblod cl-price">{{item.coupon_money}}</div>
								</div>
							</div>
							
							<div class="button-write-off" v-if="item.status==0" @click="write_off(index)">核销</div>
							<div class="button-write-off-over" v-if="item.status==1">核销完</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="flex center mt20" v-else>
				<a-empty/>
			</div>
					
			<div class="flex center mt20">
				<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
			</div>
		</a-spin>
				
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 6, //每页中显示10条数据
					total: 0,
				},
				datas:[],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getMemberCouponList',{
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			write_off(index){
				this.$confirm({
					title: '确定核销这个优惠券吗？',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						return new Promise((resolve,reject)=>{
							this.$http.api('writeOffCoupon', {
								member_id:this.member_id,
								member_coupon_id:this.datas[index].member_coupon_id,
							}).then(res => {
								this.$message.success('核销成功',1,()=>{
									this.$emit('refreash')
								})
							}).catch(res => {
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					},
				});
			}
		}
	}
</script>

<style>
	@media screen and (max-width: 1450px) {
		.detail-coupon-item{
			width: 99%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
	
	
	@media screen and (min-width: 1451px) and (max-width: 1850px) {
		.detail-coupon-item{
			width: 49%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
	
	@media screen and (min-width:1851px){
		.detail-coupon-item{
			width: 32%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
</style>
